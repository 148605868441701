import React, { useState } from "react";
import To6 from "./To6";
import { Link } from "react-router-dom";
import { Slider } from "infinite-react-carousel";
import {
  BookmarkIcon,
  CalenderIcon,
  GroupIcon,
  HomeIcon,
  KidStar1Icon,
  LocationIcon,
  PaymentIcon,
  VideoSvg,
} from "../../data/Data";
import { planInfo } from "../../data/Info";
import OnlineCard from "./OnlineCard";
import { getMyOwn } from "../../controller/Functions";
import ImageCard from "./ImageCard";

const ProfileCard = ({
  imgAds,
  imgs,
  vids,
  plan,
  name,
  price,
  rating,
  age,
  city,
  ownplace,
  followers,
  sex,
  username,
  lastOnline,
  iallow,
  md,
  sm,

  who,
}) => {
  const planDetail = planInfo(plan);

  const [activeSlide, setActiveSlide] = useState(0);

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };

  const go = `/u/${username}`;

  return (
    <div
      className={`relative z-10 ${planDetail?.btnbtn} ${md ? "min-w-60" : ""} ${
        sm ? "min-w-52 rounded-xl" : "sm:w-64 rounded-3xl"
      } text-xs mb-5  h-max tc m-0`}
    >
      <div
        className={`relative z-10 ${
          sm ? "h-40 rounded-t-xl" : "h-64 sm:h-48 w-full rounded-t-3xl"
        } relative z-10 bglb   overflow-hidden`}
      >
        <Slider slidesToShow={1} arrows={false} dots={false} className="h-full">
          <ImageCard
            src={imgAds}
            alt=""
            className="w-full h-64 sm:h-48 object-cover "
          />
          {imgs?.length > 0 &&
            imgs.map((img, index) => (
              <div key={index} className="relative h-full">
                <ImageCard
                  src={img}
                  alt=""
                  className="w-full h-64 sm:h-48 object-cover"
                />
                {plan !== "free" && plan !== "tele" && (
                  <span
                    className={`${planDetail?.planTextbc} ${planDetail?.planTexttc} ${planDetail?.planTextbr}   absolute top-2 left-2 py-1 px-1 w-14 text-center font-medium text-10 capitalize rounded-2xl`}
                  >
                    {planDetail?.planText}
                  </span>
                )}
                <div className="absolute top-60  sm:top-44 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  {imgs.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`w-1 h-1 rounded-full cursor-pointer ${
                        activeSlide === index ? "bg-blue-500" : "bg-gray-300"
                      }`}
                    />
                  ))}
                </div>
              </div>
            ))}
        </Slider>
      </div>
      <div
        className={`px-5 pt-2 py-5  ${sm ? "rounded-b-xl" : "rounded-b-3xl"} ${
          planDetail?.textClass
        } ${planDetail?.bgClass} overflow-hidden`}
      >
        <div>
          <Link
            to={go}
            className={`text-sm font-semibold capitalize ${planDetail?.nameClass}`}
          >
            {name}
          </Link>
          <div
            className={`flex text-8 items-center gap-3 py-2 mb-2 ${planDetail?.lineClass}`}
          >
            <OnlineCard />
          </div>
        </div>
        <div className="flex justify-between items-center my-2 font-bold text-xs">
          <div className="flex items-center gap-3">
            {who !== "content" && (
              <PaymentIcon size={14} color={planDetail?.iconClass} />
            )}
            {who === "content" && <VideoSvg />}
            {who !== "content" && <span className="-ml-1">R$ {price}/h</span>}
            {who === "content" && <span className="-ml-1">+99 conteúdos</span>}
          </div>
          <div className="flex items-center gap-3">
            <KidStar1Icon size={12} color={planDetail?.iconClass} />
            <span className="-ml-1">{rating}</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-1 ">
          <To6
            color={planDetail?.iconsClass}
            cols={planDetail?.textClass}
            size={14}
            sm={true}
            svg={CalenderIcon}
            text={`${age} anos`}
          />
          {who === "escort" && (
            <>
              <To6
                color={planDetail?.iconsClass}
                cols={planDetail?.textClass}
                size={14}
                svg={LocationIcon}
                text={`${city}`}
                sm={true}
              />
            </>
          )}
          <To6
            color={planDetail?.iconsClass}
            cols={planDetail?.textClass}
            svg={BookmarkIcon}
            sm={true}
            text={`${followers} Seguidores`}
            size={14}
          />
          {who === "escort" && (
            <>
              <To6
                color={planDetail?.iconsClass}
                cols={planDetail?.textClass}
                size={14}
                svg={HomeIcon}
                sm={true}
                text={`${ownplace ? "Com local" : "não com local"}`}
              />
              <To6
                color={planDetail?.iconsClass}
                cols={planDetail?.textClass}
                size={14}
                svg={GroupIcon}
                sm={true}
                text={getMyOwn(sex)}
              />
            </>
          )}
        </div>
        {plan === "tele" && (
          <Link to={go}>
            <div className="themecom py-2 font-semibold text-xs rounded-md my-3 text-center">
              Ver telefone
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
