import React, { useEffect, useState } from "react";
import To9 from "../cards/To9";
import Step from "../cards/Step";
import { sexOptions } from "../../data/Input";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { stater, Toast, useGeneralBrain } from "../../controller/Brain";
import { toast } from "react-toastify";
import { generateUid16, getSex } from "../../controller/Functions";
import {
  doc,
  getDoc,
  increment,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useNavigate, useParams } from "react-router-dom";

const Sellers = ({
  fullname,
  setFullname,
  efullname,
  username,
  setUsername,
  eusername,
  email,
  setRegisterEmail,
  eregisterEmail,
  password,
  setRegisterPassword,
  eregisterPassword,
  confirmPassword,
  setConfirmPassword,
  econfirmPassword,
  terms,
  setTerms,
  ager,
  setAger,
  s1,
  s2,
  s3,
  sets1,
  sets2,
  sets3,
  handleRegister,
  loading,
  who,
  step,
  setStep,
  obj,
  editContent,
  isValidated,
  error,
}) => {
  const { userData, currentUser } = useGeneralBrain();
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));

  const { query } = useParams();

  const intialContent = query
    ? editContent
    : {
        price: "",
        title: "",
        desc: "",
        content: "",
        url: "",
        prevImgs: [],
        prevVids: [],
        cats: [],
        type: "content",
        time: serverTimestamp(),
      };
  const [active, setactive] = useState(step);
  let title;
  let desc;

  if (step === 1) {
    title = "Faça seu cadastro!";
    desc =
      "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  } else if (step === 2) {
    title = "Valide sua identidade";
    desc =
      "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  } else if (step === 3) {
    title = who === "content" ? "Publique seu conteúdo" : "Crie seu anúncio";
    desc =
      obj === "content"
        ? "Preencha os dados abaixo e crie seu anúncio!"
        : "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  }

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  const tile3 =
    userData?.who === "content" ? "Publicar conteúdo" : "Criar anúncio";

  const [values, setvalues] = useState([{ time: "", price: "" }]);
  const [exp, setexp] = useState([
    { day: "Segunda-feira", start: "", end: "", work: true },
    { day: "Terça-feira", start: "", end: "", work: true },
    { day: "Quarta-feira", start: "", end: "", work: true },
    { day: "Quinta-feira", start: "", end: "", work: true },
    { day: "Sexta-feira", start: "", end: "", work: true },
    { day: "Sábado", start: "", end: "", work: true },
    { day: "Domingo", start: "", end: "", work: true },
  ]);

  const [isValidated3, setValidated3] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [contents, setcontents] = useState(intialContent);
  const [prevImgs1, setprevImgs1] = useState([]);
  const [prevVids1, setprevVids1] = useState([]);

  const [escortData, setEscortData] = useState({
    imgCover: "",
    imgPfp: "",
    imgAds: "",
    imgs: [],
    imgsVid: [],

    awho: userData?.who,
    arate: userData?.myRate,
    ausername: userData?.userName,
    afullname: "",
    asex: "",
    adob: "",
    aservice: "",
    atelephone: "",
    awhatsapp: "",
    adesc: "",

    lcountry: "Brazil",
    lstate: "",
    lcity: "",
    lstater: "",
    ltimeregion: "",

    slown: false,
    slhotel: false,
    slmotel: false,
    slevent: false,
    slhome: false,
    slother: false,

    myvalues: values,
    myexps: exp,

    // characteristics
    chapresex: "",
    chaheight: "",
    chaweight: "",
    chaeyecolor: "",
    chaethnicity: "",
    chahairsize: "",
    chahairstyle: "",
    chasilicone: "",
    chafootsize: "",
    chapiercing: "",
    chatatoo: "",
    chalanguage: "",

    myservs1: false,
    myservs2: false,
    myservs3: false,
    myservs4: false,
    myservs5: false,
    myservs6: false,
    myservs7: false,
    myservs8: false,
    myservs9: false,
    myservs10: false,
    myservs11: false,
    myservs12: false,
    myservs13: false,
    myservs14: false,
    myservs15: false,

    pmmoney: false,
    pmpix: false,
    pmcredit: false,
    pmdebit: false,
  });
  const [error1, seterror1] = useState("");
  const [escortDatae, setEscortDatae] = useState({});

  useEffect(() => {
    // Start with an empty error object
    const errors = {};
    setEscortDatae({});
    seterror1("");

    const dob = new Date(escortData.adob);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    // Validate each field in order
    if (!escortData?.afullname) {
      errors.afullname = "Nome completo é obrigatório";
    } else if (escortData.afullname.trim().split(" ").length < 2) {
      errors.afullname = "O nome completo deve ter pelo menos dois nomes";
    } else if (!escortData?.asex) {
      errors.asex = "Sexo é obrigatório";
    } else if (!escortData?.adob) {
      errors.adob = "Data de nascimento é obrigatória";
    } else if (isNaN(new Date(escortData.adob).getTime())) {
      errors.adob = "Data de nascimento inválida";
    } else if (age < 18 || (age === 18 && monthDiff < 0)) {
      errors.adob = "Você deve ter pelo menos 18 anos";
    } else if (!escortData?.aservice) {
      errors.aservice = "Serviço é obrigatório";
    } else if (!escortData?.atelephone) {
      errors.atelephone = "Número de telefone é obrigatório";
    } else if (!escortData?.awhatsapp) {
      errors.awhatsapp = "WhatsApp é obrigatório";
    } else if (!escortData?.adesc) {
      errors.adesc = "Descrição é obrigatória";
    } else if (!escortData?.lcountry) {
      errors.lcountry = "País é obrigatório";
    } else if (!escortData?.lstate) {
      errors.lstate = "Estado é obrigatório";
    } else if (!escortData?.lcity) {
      errors.lcity = "Cidade é obrigatória";
    } else if (!escortData?.lzona) {
      errors.lzona = "Zona é obrigatório";
    } else if (!escortData?.lstreet) {
      errors.lstreet = "Street é obrigatória";
    } else if (!escortData?.lstater) {
      errors.lstater = "Estado/Região é obrigatório";
    } else if (
      [
        escortData.slown,
        escortData.slhotel,
        escortData.slmotel,
        escortData.slevent,
        escortData.slhome,
        escortData.slother,
      ].filter(Boolean).length < 2
    ) {
      errors.locations = "É necessário selecionar pelo menos dois.";
    } else if (
      [
        escortData.sgac,
        escortData.sgshower,
        escortData.sgminibar,
        escortData.sgwifi,
        escortData.sgtv,
        escortData.sgparking,
      ].filter(Boolean).length < 2
    ) {
      errors.amenities = "É necessário selecionar pelo menos dois.";
    } else if (
      [
        escortData.chapresex,
        escortData.chaheight,
        escortData.chaweight,
        escortData.chaeyecolor,
        escortData.chaethnicity,
        escortData.chahairsize,
        escortData.chahairstyle,
        escortData.chafootsize,
        escortData.chalanguage,
        escortData.chaclientes,
        escortData.chaatende,
      ].some((field) => !field) ||
      [
        escortData.chasilicone,
        escortData.chapiercing,
        escortData.chatatoo,
        escortData.chafumante,
      ].some((field) => field === undefined)
    ) {
      errors.characteristics =
        "Todos os campos de características são obrigatórios.";
    } else if (
      escortData.myvalues.length === 0 ||
      !escortData.myvalues.some((value) => value.time === "1 hora") ||
      escortData.myvalues.some((value) => !value.price) ||
      new Set(escortData.myvalues.map((value) => value.time)).size !==
        escortData.myvalues.length
    ) {
      errors.myvalues =
        "É necessário selecionar pelo menos um valor, incluindo '1 hora', e garantir que todos os preços estão preenchidos e os horários são únicos.";
    } else if (
      [
        escortData.pmmoney,
        escortData.pmpix,
        escortData.pmcredit,
        escortData.pmdebit,
      ].filter(Boolean).length < 2
    ) {
      errors.paymentMethods = "É necessário selecionar pelo menos dois.";
    } else if (!escortData?.ltimeregion) {
      errors.ltimeregion = "Fuso horário é obrigatório";
    } else if (
      exp.some(
        (exp) => exp.work !== false && (exp.start === "" || exp.end === "")
      )
    ) {
      errors.myexps =
        "Para cada dia em que o trabalho', é necessário fornecer um horário de início e de término.";
    } else if (
      [
        escortData.myservs1,
        escortData.myservs2,
        escortData.myservs3,
        escortData.myservs4,
        escortData.myservs5,
        escortData.myservs6,
        escortData.myservs7,
        escortData.myservs9,
        escortData.myservs10,
        escortData.myservs11,
        escortData.myservs12,
        escortData.myservs13,
        escortData.myservs14,
        escortData.myservs15,
      ].filter(Boolean).length < 2
    ) {
      errors.activity = "É necessário selecionar pelo menos dois.";
    } else if (!escortData?.imgAds) {
      errors.imgAds = "Imagem do anúncio é obrigatório";
    } else if (!escortData?.imgCover || !escortData?.imgPfp) {
      seterror1("Cover picture and profile picture é obrigatório");
    } else if (
      !contents?.price ||
      !contents?.title ||
      !contents?.desc ||
      (!contents?.content && !contents?.url) ||
      !contents?.prevImgs?.length < 1 ||
      !contents?.cats < 2
    ) {
    }

    // If there are errors, set them in state and mark form as not validated
    if (Object.keys(errors).length > 0) {
      setEscortDatae(errors);
      setValidated3(false);
    } else {
      // If no errors, clear the error state and mark form as validated
      setEscortDatae({});
      setValidated3(true);
    }

    /* eslint-disable-next-line */
  }, [escortData, exp]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEscortData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: value,
      };

      return updatedFormData;
    });
  };

  useEffect(() => {
    const name = "myvalues";
    setEscortData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: values,
      };

      return updatedFormData;
    });
  }, [values]);

  const handleSelect = (value, name) => {
    const e = {
      target: {
        name,
        value,
      },
    };
    handleChange(e);
    setDropdowns(Array(18).fill(false));
  };

  const handleCheck = (value, name) => {
    const e = {
      target: {
        name,
        value: value === true ? false : true,
      },
    };
    handleChange(e);
    setDropdowns(Array(18).fill(false));
  };

  const navigate = useNavigate();

  const handleUpload = async (type) => {
    try {
      setisloading(true);
      const mysexdoc = getSex(escortData.asex);

      const livePost = `${escortData.lstater}-${mysexdoc}-post`;
      const liveEscort = `${escortData.lstater}-${mysexdoc}`;

      const uniqueDocId = generateUid16();
      await runTransaction(db, async (transaction) => {
        const livEeRef = doc(db, "lives", liveEscort);
        const livePRef = doc(db, "lives", livePost);

        const livEeDoc = await getDoc(livEeRef);
        if (!livEeDoc.exists()) {
          transaction.set(livEeRef, {});
          console.log("Document livEeRef created with empty object");
        } else {
          console.log("Document livEeRef already exists");
        }

        // Check and set for livePRef
        const livePDoc = await getDoc(livePRef);
        if (!livePDoc.exists()) {
          transaction.set(livePRef, {});
          console.log("Document livePRef created with empty object");
        } else {
          console.log("Document livePRef already exists");
        }

        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        transaction.update(escortContentsRef, {
          [uniqueDocId]: contents,
        });

        escortData.imgs.forEach((imgUrl) => {
          const uniqueDocId = generateUid16();
          const content = {
            n: escortData.afullname,
            u: userData?.userName,
            l: 0,
            t: serverTimestamp(),
            type: "video",
            url: imgUrl,
          };
          transaction.update(escortContentsRef, {
            [uniqueDocId]: {
              url: imgUrl,
              type: "image",
              time: serverTimestamp(),
            },
          });

          if (type === "publish") {
            transaction.update(livePRef, {
              [uniqueDocId]: content,
            });
          }
        });

        escortData.imgsVid.forEach((vidUrl) => {
          const uniqueDocId = generateUid16();
          const content = {
            n: escortData.afullname,
            u: userData?.userName,
            l: 0,
            t: serverTimestamp(),
            type: "video",
            url: vidUrl,
          };

          transaction.update(escortContentsRef, {
            [uniqueDocId]: {
              url: vidUrl,
              type: "video",
              time: serverTimestamp(),
            },
          });

          if (type === "publish") {
            transaction.update(livePRef, {
              [uniqueDocId]: content,
            });
          }
        });

        const at1 = {
          todo: "",
          status: "active",

          xmyp: escortData.imgs.length,
          xmyv: escortData.imgsVid.length,
          xmyc: 1,

          imgCover: escortData.imgCover,
          imgPfp: escortData.imgPfp,
          imgAds: escortData.imgAds,

          userFullname: escortData.afullname,
          myGender: escortData.asex,

          adob: escortData.adob,
          aservice: escortData.aservice,
          atelephone: escortData.atelephone,
          awhatsapp: escortData.awhatsapp,
          adesc: escortData.adesc,

          lcountry: escortData.lcountry,
          lstate: escortData.lstate,
          lcity: `${escortData.lcity}-${escortData.lstater
            .slice(-2)
            .toUpperCase()}`,
          lzona: escortData.lzona,
          lstreet: escortData.lstreet,
          lstater: escortData.lstater,

          chapresex: escortData.chapresex,
          chaheight: escortData.chaheight,
          chaweight: escortData.chaweight,
          chaeyecolor: escortData.chaeyecolor,
          chaethnicity: escortData.chaethnicity,
          chahairsize: escortData.chahairsize,
          chahairstyle: escortData.chahairstyle,
          chasilicone: escortData.chasilicone,
          chafootsize: escortData.chafootsize,
          chapiercing: escortData.chapiercing,
          chatatoo: escortData.chatatoo,
          chalanguage: escortData.chalanguage,
          chafumante: escortData.chafumante,
          chaclientes: escortData.chaclientes,
          chaatende: escortData.chaatende,

          slown: escortData.slown,
          slhotel: escortData.slhotel,
          slmotel: escortData.slmotel,
          slevent: escortData.slevent,
          slhome: escortData.slhome,
          slother: escortData.slother,

          sgac: escortData.sgac || false,
          sgshower: escortData.sgshower || false,
          sgminibar: escortData.sgminibar || false,
          sgwifi: escortData.sgwifi || false,
          sgtv: escortData.sgtv || false,
          sgparking: escortData.sgparking || false,

          myvalues: escortData.myvalues,
          myexps: exp,

          myservs1: escortData.myservs1,
          myservs2: escortData.myservs2,
          myservs3: escortData.myservs3,
          myservs4: escortData.myservs4,
          myservs5: escortData.myservs5,
          myservs6: escortData.myservs6,
          myservs7: escortData.myservs7,
          myservs8: escortData.myservs8,
          myservs9: escortData.myservs9,
          myservs10: escortData.myservs10,

          pmmoney: escortData.pmmoney,
          pmpix: escortData.pmpix,
          pmcredit: escortData.pmcredit,
          pmdebit: escortData.pmdebit,
        };
        const at2 = {
          d0: userData?.who,
          d1: escortData.imgAds,
          d2: "",
          d3: "",
          d4: userData?.myPlan,
          d5: escortData.afullname,
          d6: escortData.myvalues.find((item) => item.time === "1 hora")?.price,
          d7: userData?.myRate,
          d8: escortData.adob,
          d9: `${escortData.lcity}-${escortData.lstater
            .slice(-2)
            .toUpperCase()}`,
          d10: escortData.slown,
          d11: escortData.asex,
          d12: userData?.userName,
          d13: userData?.myFollowers,
          d14: serverTimestamp(),
        };

        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, at1);

        if (type === "publish") {
          const liveRef = doc(db, "lives", liveEscort);
          transaction.update(liveRef, {
            [currentUser.uid]: at2,
          });
        }
      }).then(navigate(`/acompanhantes-${stater}`));
    } catch (error) {
      console.error("Error here:", error);
      setisloading(true);
    }
  };

  const publishNow = async () => {
    try {
      await handleUpload("publish");
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const cancelNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        if (!obj) {
          const userRef = doc(db, "users", currentUser.uid);
          transaction.update(userRef, {
            todo: "",
          });
        }

        toast("Done succesfully!");
      }).then(navigate(`/acompanhantes-${stater}`));
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const publishEditNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        transaction.update(escortContentsRef, {
          [query]: contents,
        });
      }).then(navigate(`/meus-conteudos`));
      Toast.fire({
        icon: "success",
        title: `Done successfully`,
      });
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const publishContentNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        const uniqueDocId = generateUid16();
        transaction.update(escortContentsRef, {
          [uniqueDocId]: contents,
        });

        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, {
          xmyc: increment(1),
        });
      }).then(navigate(`/meus-conteudos`));
      Toast.fire({
        icon: "success",
        title: `Done successfully`,
      });
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const publishMediaNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        console.log("postings");
        const mysexdoc = getSex(userData.myGender);
        const livePost = `${userData?.lstater}-${mysexdoc}-post`;

        const livePRef = doc(db, "lives", livePost);
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        prevImgs1.forEach((imgUrl) => {
          const uniqueDocId = generateUid16();
          const content = {
            n: userData?.userFullname,
            u: userData?.userName,
            l: 0,
            t: serverTimestamp(),
            type: "image",
            url: imgUrl,
          };
          transaction.update(escortContentsRef, {
            [uniqueDocId]: {
              url: imgUrl,
              type: "image",
              time: serverTimestamp(),
            },
          });

          transaction.update(livePRef, {
            [uniqueDocId]: content,
          });
        });

        prevVids1.forEach((vidUrl) => {
          const uniqueDocId = generateUid16();
          const content = {
            n: userData?.userFullname,
            u: userData?.userName,
            l: 0,
            t: serverTimestamp(),
            type: "video",
            url: vidUrl,
          };

          transaction.update(escortContentsRef, {
            [uniqueDocId]: {
              url: vidUrl,
              type: "video",
              time: serverTimestamp(),
            },
          });

          transaction.update(livePRef, {
            [uniqueDocId]: content,
          });
        });

        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, {
          xmyv: increment(prevVids1.length),
          xmyp: increment(prevImgs1.length),
        });
      }).then(navigate(`/meus-conteudos`));
      Toast.fire({
        icon: "success",
        title: `Done successfully`,
      });
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const draftEditNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        const updatedContents = {
          ...contents,
          status: "draft",
        };
        transaction.update(escortContentsRef, {
          [query]: updatedContents,
        });

        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, {
          xmyc: increment(-1),
        });
      }).then(navigate(`/meus-conteudos`));
      Toast.fire({
        icon: "success",
        title: `Done successfully`,
      });
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const draftContentNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        const updatedContents = {
          ...contents,
          status: "draft",
        };
        transaction.update(escortContentsRef, {
          [query]: updatedContents,
        });
      }).then(navigate(`/meus-conteudos`));
      Toast.fire({
        icon: "success",
        title: `Done successfully`,
      });
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  let publish;
  let draft;

  if (obj === "edit-content") {
    publish = publishEditNow;
    draft = draftEditNow;
  } else if (obj === "publish-content") {
    publish = publishContentNow;
    draft = draftContentNow;
  } else if (obj === "publish-media") {
    publish = publishMediaNow;
  } else {
    publish = publishNow;
  }

  return (
    <div className="sm:flex sm:justify-center sm:items-center">
      <div className="c-form-container width-lg transition-all ease-in-out">
        {obj && (
          <To9
            title={
              obj === "publish-content"
                ? "Publique seu conteúdo"
                : obj === "publish-media"
                ? "Publique fotos e vídeos"
                : "Editar Conteúdo"
            }
            desc={
              obj === "publish-content"
                ? "Preencha os dados abaixo e crie seu anúncio!"
                : obj === "publish-media"
                ? "Adicionar foto ou vídeo à galeria!"
                : "Edite os dados do seu anúncio."
            }
          />
        )}
        {!obj && (
          <>
            <To9 title={title} desc={desc} />
            <Step
              value={active}
              setValue={setactive}
              t1={"Cadastro"}
              t2={"Validação"}
              t3={tile3}
            />
          </>
        )}
        {step === 1 && (
          <Step1
            setStep={setStep}
            step={step}
            fullname={fullname}
            setFullname={setFullname}
            efullname={efullname}
            username={username}
            setUsername={setUsername}
            eusername={eusername}
            email={email}
            setRegisterEmail={setRegisterEmail}
            eregisterEmail={eregisterEmail}
            password={password}
            setRegisterPassword={setRegisterPassword}
            eregisterPassword={eregisterPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            econfirmPassword={econfirmPassword}
            terms={terms}
            setTerms={setTerms}
            ager={ager}
            setAger={setAger}
            s1={s1}
            s2={s2}
            s3={s3}
            sets1={sets1}
            sets2={sets2}
            sets3={sets3}
            loading={loading}
            handleRegister={handleRegister}
            isValidated={isValidated}
            error={error}
          />
        )}

        {step === 2 && (
          <Step2
            toggleDropdown={toggleDropdown}
            handleSelect={handleSelect}
            dropdowns={dropdowns}
            setDropdowns={setDropdowns}
            cancelNow={cancelNow}
            step={step}
            setStep={setStep}
            name={fullname}
          />
        )}

        {step === 3 && (
          <>
            <Step3
              obj={obj}
              who={"escort"}
              toggleDropdown={toggleDropdown}
              handleSelect={handleSelect}
              sexOptions={sexOptions}
              dropdowns={dropdowns}
              escortData={escortData}
              setEscortData={setEscortData}
              publishNow={publish}
              draftNow={draft}
              cancelNow={cancelNow}
              handleChange={handleChange}
              handleCheck={handleCheck}
              values={values}
              setvalues={setvalues}
              prevImgs1={prevImgs1}
              prevVids1={prevVids1}
              setprevImgs1={setprevImgs1}
              setprevVids1={setprevVids1}
              exp={exp}
              setexp={setexp}
              contents={contents}
              setcontents={setcontents}
              loading={isloading}
              isValidated={isValidated3}
              escortDatae={escortDatae}
              error1={error1}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Sellers;
