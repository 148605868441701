import React from "react";
import { BacktopIcon } from "../../data/Data";

const BackTop = () => {
  return (
    <div onClick={() => window.scrollTo(0, 0)} className="flex justify-center items-center my-5 bg">
      <BacktopIcon size={28}/>
    </div>
  );
};

export default BackTop;
