import React, { useState } from "react";
import { CloudUploadIcon, Delete1Icon, QmarkIcon } from "../../data/Data";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../utils/init-firebase";
import { useAuth } from "../../contexts/AuthContext";

const To13 = ({ t1, t2, t3, type, id, value, setValue, error, theme, accept }) => {
  const { currentUser } = useAuth();
  const [filePreview, setFilePreview] = useState("");
  const [uploading, setUploading] = useState(false); // For showing upload status

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFilePreview(selectedFile);
      setUploading(true); 

      const storageImgRef = ref(
        storage,
        `users/${currentUser?.uid}/${selectedFile.name}`
      );

      // Upload the file to Firebase Storage
      const uploadTask = uploadBytesResumable(storageImgRef, selectedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: You can track upload progress here if needed
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const imgUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setValue(imgUrl); // Set the value to the URL
          setUploading(false); // Reset uploading state
        }
      );
    }
  };

  const handleDelete = () => {
    setValue(null);
    setFilePreview("");
  };

  const isImageFile = (file) => {
    return file && file.type?.startsWith("image/");
  };

  const isVideoFile = (file) => {
    return file && file.type?.startsWith("video/");
  };

  return (
    <div className="my-5">
      <label
        htmlFor={id}
        className={`${
          theme ? "btnbtn" : "btnbtn21 pt-10"
        } cursor-pointer rounded-2xl p-5 flex flex-col text-center justify-center items-center`}
      >
        <input
          type={type}
          accept="image/*,video/*"
          onChange={handleFileChange}
          className="hidden"
          id={id}
        />
        <div>
          <CloudUploadIcon />
        </div>
        <div className="text-sm font-medium mt-2">
          <span className="theme">{t1}</span>
          <span>{t2}</span>
        </div>
        <div className="text-xs">{t3}</div>
      </label>
      {uploading && (
        <div className="btnbtn21 text-xs rounded-xl px-2 py-3 my-5 flex justify-between items-center">
          Uploading...
        </div>
      )}{" "}
      {/* Show uploading status */}
      {value && (
        <div className="btnbtn21 text-xs rounded-xl px-2 py-3 my-5 flex justify-between items-center">
          <div className="h-10 w-10 themecom rounded-md">
            {isImageFile(filePreview) && (
              <img
                src={URL.createObjectURL(filePreview)}
                alt={filePreview.name}
                className="h-full w-full object-cover"
              /> 
            )}
            {isVideoFile(filePreview) && (
              <video
                src={URL.createObjectURL(filePreview)}
                alt={filePreview.name}
                controls
                className="h-full w-full object-cover"
              />
            )}
          </div>
          <div>{filePreview.name || "File"}</div>
          <div onClick={handleDelete} className="hover">
            <Delete1Icon />
          </div>
        </div>
      )}
      {error && (
        <div className="flex red my-5 items-center mb-4 text-xs">
          <QmarkIcon color="var(--themeColor)" size={10} />
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

export default To13;
