import React from "react";
import ErrorCard from "./ErrorCard";

const To8a = ({bg, placehoder, name, type, onChange, value, error }) => {
  return (
    <>
      <textarea
        type={type ? type : "text"}
        placeholder={placehoder}
        className={`textarea-btn bg tc ${bg ? 'h-48' : 'h-20'} text-xs  btnbtn21 rounded-md`}
        onChange={onChange}
        value={value}
        name={name}
      />
      {error && <ErrorCard error={error} />}
    </>
  );
};

export default To8a;
