import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { stater, useGeneralBrain } from "../controller/Brain";
import { useAuth } from "../contexts/AuthContext";
import SideLink from "./cards/SideLink";
import {
  AdminSvg,
  CartIcon,
  HamburgerIcon,
  LoginSvg,
  PFPIcon,
  RegisterSvg,
} from "../data/Data";

import { ThemeContext } from "../contexts/ThemeContext";
import SideBar from "../components/SideBar";
import { domain } from "../data/Info";
import { getObjectLength } from "../controller/Functions";

const Navbar = ({ open, setOpen }) => {
  const { currentUser, userData } = useGeneralBrain();
  const { theme } = useContext(ThemeContext);

  const { logout } = useAuth();

  const [bar, setbar] = useState(false);

  const openBar = () => {
    setbar(bar === true ? false : true);
  };

  const openNav = () => {
    setOpen(true);
  };

  const closeNav = () => {
    setOpen(false);
  };

  const location = useLocation();
  useEffect(() => {
    setbar(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const url = `/u/${userData?.userName}`;

  const CartCount = getObjectLength(userData?.deeCart);

  return (
    <div className="navbar-container -ml-2 shadow">
      <div className="nav-first-con">
        <Link to="/" className="">
          <div className="navbar-logo-img">
            <img
              src={
                theme === "light"
                  ? `${domain}/darkLogo.svg`
                  : `${domain}/lightLogo.svg`
              }
              alt="logo"
              className="navbar-logo"
            />
          </div>
        </Link>
        <div className="navbar-links lg:ml-5">
          <Link to={`/acompanhantes-${stater}`} className="navbar-link dart">
            Acompanhantes
          </Link>
          <Link to={`/posts-${stater}`} className="navbar-link dart">
            Posts
          </Link>
          {(!currentUser || userData?.who !== "client") && (
            <Link to={"/planos"} className="navbar-link dart">
              Planos
            </Link>
          )}
          <Link to={"/blog"} className="navbar-link dart">
            Blog
          </Link>
          <Link to={"/contato"} className="navbar-link dart">
            Contato
          </Link>
        </div>
      </div>
      <div className="navbar-links">
        {!currentUser && (
          <>
            <Link to="/login" className="navbar-link dart">
              Log in
            </Link>
            <Link to="/register" className="navbar-link hover btn1">
              Cadastre-se
            </Link>
          </>
        )}

        {currentUser && (
          <>
            <div className="navbar-link flex justify-between items-center">
              {currentUser && userData?.isAdmin && (
                <Link to={"/admin-panel"} className="navbar-link mr-2">
                  <AdminSvg size={18} color="var(--textColor)" />
                </Link>
              )}
              <Link to={"/carrinho"} className="navbar-link">
                <div className="flex  ">
                  <CartIcon size={18} color="var(--textColor)" />
                  {CartCount > 0 && (
                    <div className="text-8  animate-bounce duration-[200] -mx-2 -mt-3 p-1 text-center font-medium tcw themecom rounded-full ">
                      {CartCount}
                    </div>
                  )}
                </div>
              </Link>
              <Link to={url} className="mx-2 navbar-link">
                <PFPIcon size={25} />
              </Link>
              <div className="navbar-link" onClick={openBar}>
                {!bar ? (
                  <HamburgerIcon size={20} color="var(--textColor)" />
                ) : (
                  <div className="navbar-line-single"></div>
                )}
              </div>
            </div>
            {bar && (
              <div className="min-h-40 bg absolute z-10 top-24 w-60 right-20 box-shadow rounded-lg hidden sm:block">
                <SideBar
                  userData={userData}
                  logout={async (e) => {
                    e.preventDefault();
                    await logout();
                  }}
                />
              </div>
            )}
          </>
        )}

        <div className="navbar-authlink">
          <>
            <div className="navbar-burger" onClick={openNav}>
              <div className="navbar-line"></div>
              <div className="navbar-line"></div>
              <div className="navbar-line"></div>
            </div>
            <div
              className={`navbar-sidenav box-shadow ${
                open ? "navbar-open" : ""
              }`}
            >
              <div className="navbar-close" onClick={closeNav}>
                <div className="navbar-line-single"></div>
              </div>

              {!currentUser && (
                <>
                  <SideLink
                    text={"Acompanhantes"}
                    url={`/acompanhantes-${stater}`}
                  />
                  <SideLink text={"Posts"} url={`/posts-${stater}`} />
                  <SideLink text={"Planos"} url={"/planos"} />
                  <SideLink text={"Blog"} url={"/blog"} />
                  <SideLink text={"Contato"} url={"/contato"} />

                  <SideLink text={" Log In"} url={"/login"} svg={LoginSvg} />
                  <SideLink
                    text={"Register"}
                    url={"/register"}
                    svg={RegisterSvg}
                  />
                </>
              )}
              {currentUser && (
                <>
                  <SideBar
                    userData={userData}
                    logout={async (e) => {
                      e.preventDefault();
                      await logout();
                    }}
                  />
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Navbar;