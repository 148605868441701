import React from "react";
import ToggleCard from "./ToggleCard";
import To8 from "./To8";

const To36 = ({ value, setValue }) => {
  // Function to handle change for time fields (start and end times)
  const handleChange = (e, index, field) => {
    const newValue = [...value]; // Copy the current value array
    newValue[index][field] = e.target.value; // Update the specific field (start/end time)
    setValue(newValue); // Update the state
  };

  // Function to handle toggle state change (whether worker works that day)
  const handleToggleChange = (index) => {
    const newValue = [...value]; // Copy the current value array
    newValue[index].work = !newValue[index].work; // Toggle the work field
    setValue(newValue); // Update the state
  };

  // Component to display each day's time inputs
  const Comp = ({ day, index, start, end, work }) => {
    return (
      <div className="mb-3">
        <div className="flex items-center text-sm">
          {/* Toggle to control whether the worker works that day */}
          <ToggleCard value={work} setValue={() => handleToggleChange(index)} /> 
          <div>{day}</div>
        </div>
        {/* Conditionally show the time inputs only if the worker works that day */}
        {work && (
          <div className="grid grid-cols-2 items-center gap-x-3">
            <To8
              placehoder={"De:"}
              value={start}
              onChange={(e) => handleChange(e, index, "start")}
              type={"time"}
              name={`${day.toLowerCase()}_start`}
              ph={"De:"}
            />
            <To8
              placehoder={"Até:"}
              value={end}
              onChange={(e) => handleChange(e, index, "end")}
              type={"time"}
              name={`${day.toLowerCase()}_end`}
              ph={"Até:"}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {value.map((item, index) => (
        <Comp
          key={index}
          day={item.day}
          index={index}
          start={item.start}
          end={item.end}
          work={item.work} // Pass whether they work that day
        />
      ))}
    </div>
  );
};

export default To36;
