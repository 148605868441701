import React from "react";
import To25 from "./To25";
import To15 from "./To15";

const To26b = ({ prevImgs, setprevImgs, prevVids, setprevVids }) => {
  return (
    <div>
      <To15
        title={"Galeria"}
        subtitle={"Adicione as fotos e vídeos que deseja exibir no seu perfil."}
      />
      <To25
        key={1}
        title={"Fotos"}
        type={"image"}
        text={"Adicionar foto"}
        limit={"Limite de 5MB"}
        value={prevImgs}
        setValue={setprevImgs}
        id={"imgs"}
      />
      <To25
        key={2}
        title={"Vídeos"}
        type={"video"}
        text={"Adicionar vídeo"}
        limit={"Limite de 20MB"}
        value={prevVids}
        setValue={setprevVids}
        id={"imgsVid"}
      />
    </div>
  );
};

export default To26b;
