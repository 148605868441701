import React from "react";
import { AdminSvg, CaretDown, LogOutSvg, PFPIcon } from "../data/Data";
import { stater, useGeneralBrain } from "../controller/Brain";
import SideLink from "./cards/SideLink";

const To24 = ({ userData, logout }) => {
  const { isMobile } = useGeneralBrain();

  return (
    <>
      <div
        className={`flex justify-between items-center mb-2 ${
          isMobile ? "" : "pt-5"
        } pb-2 px-4 bord-p-bot `}
      >
        <div className="flex justify-between items-center ">
          <PFPIcon size={35} />
          <div className="font-medium text-sm">{userData?.userFullname}</div>
        </div>
        <div className="flex  items-center">
          <div className="bg-green-500 w-2 h-2 rounded-full -mr-0.5"></div>
          <CaretDown color="#808080" />
        </div>
      </div>

      {isMobile && (
        <>
          <SideLink text={"Acompanhantes"} url={`/acompanhantes-${stater}`} />
          <SideLink text={"Posts"} url={`/posts-${stater}`} />
          <SideLink text={"Planos"} url={"/planos"} />
          <SideLink text={"Blog"} url={"/blog"} />
        </>
      )}
      <SideLink text={"Meu perfil"} url={`/u/${userData?.userName}`} />
      {userData?.who === "client" && (
        <>
          <SideLink text={"Minhas compras"} url={"/minhas-compras"} />
          <SideLink text={"Meu histórico"} url={"/meu-histórico"} />
        </>
      )}
      {userData?.who !== "client" && (
        <>
          <SideLink text={"Meus conteúdos"} url={`/meus-conteudos`} />
        </>
      )}
      <SideLink text={"Editar perfil"} url={"/editar-perfil"} />
      {userData?.who !== "client" && (
        <>
          {/* <SideLink text={"Planos e assinaturas"} url={"/subscriptions"} /> */}
          <SideLink text={"Meus pagamentos"} url={"/meus-pagamentos"} />
        </>
      )}
      <SideLink text={"Configurações da conta"} url={"/settings"} />
      <SideLink text={"Suporte"} url={"/suporte"} />
      {isMobile && userData?.isAdmin && (
        <SideLink text={"Admin"} url={"/admin/overview"} svg={AdminSvg} />
      )}
      <SideLink text={"Sair"} onClick={logout} theme={true} svg={LogOutSvg} />
    </>
  );
};

export default To24;
