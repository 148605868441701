import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Container from "../controller/Container";
import { AdminAuth, ProtectedRoutes, AuthPass } from "../utils/ProtectedRoute";
import PageIsLoading from "./general/PageIsLoading";
import Roots from "../controller/Roots";

const Home = React.lazy(() => import("../pages/Home"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const Reports = React.lazy(() => import("../pages/Reports"));
const Blog = React.lazy(() => import("../pages/Blog"));
const BlogCreate = React.lazy(() => import("../pages/BlogCreate"));
const Post = React.lazy(() => import("../pages/Post"));

const AcompanContent = React.lazy(() => import("../pages/user/seller/Content"));

const Planos = React.lazy(() => import("../pages/user/Planos"));
const Perfil = React.lazy(() => import("../pages/user/Perfil"));
const Carrinho = React.lazy(() => import("../pages/user/Carrinho"));
const Settings = React.lazy(() => import("../pages/user/Settings"));
const Supports = React.lazy(() => import("../pages/user/Supports"));
const AllContent = React.lazy(() => import("../pages/user/AllContent"));

const MyPurchases = React.lazy(() =>
  import("../pages/user/client/MyPurchases")
);
const MyHistory = React.lazy(() => import("../pages/user/client/MyHistory"));

const MyPayments = React.lazy(() => import("../pages/user/seller/MyPayments"));
const PlansSub = React.lazy(() => import("../pages/user/seller/PlansSub"));

const EditContent = React.lazy(() => import("../pages/user/seller/EditContent"));
const PublicarConteúdo = React.lazy(() =>
  import("../pages/user/seller/PublishContent")
);
const PublishMedia = React.lazy(() =>
  import("../pages/user/seller/PublishMedia")
);

const AdminPanel = React.lazy(() => import("../pages/admin/AdminPanel"));

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<PageIsLoading />}>
        <Routes>
          <Route
            path="/"
            element={<Container card={Home} path={"home"} title={"home"} />}
          />

          <Route
            path="/*"
            element={
              <Container
                card={NotFound}
                path={"home"}
                title={"Not Found Page"}
              />
            }
          />
          <Route path="/:query" element={<Roots />} />

          <Route
            path="/u/:username"
            element={<Container card={Perfil} path={"home"} title={"perfil"} />}
          />
          <Route
            path="/u/:username/conteudos"
            element={
              <Container
                card={AllContent}
                path={"home"} 
                title={"Conteúdo"}
              />
            }
          />
          <Route
            path="/u/:username/conteudos/:query"
            element={
              <Container
                card={AcompanContent}
                path={"home"}
                title={"Venda de conteúdo"}
              />
            }
          />
          <Route
            path="/planos"
            element={<Container card={Planos} path={"home"} title={"planos"} />}
          />

          <Route
            path="/blog"
            element={<Container card={Blog} path={"home"} title={"blog"} />}
          />

          <Route
            path="/blog/post/:query"
            element={<Container card={Post} path={"home"} title={"post"} />}
          />
          <Route
            path="/contato"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"contato"}
              />
            }
          />
          <Route
            path="/política"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"política"}
              />
            }
          />
          <Route
            path="/termos"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"termos"}
              />
            }
          />

          {/*------------------------------ Protected Routes -------------------------------------------*/}
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/login"
              element={<Container card={Login} path={"home"} title={"login"} />}
            />
            <Route
              path="/register"
              element={
                <Container card={Register} path={"home"} title={"register"} />
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Container
                  card={ForgotPassword}
                  path={"home"}
                  title={"forgot password"}
                />
              }
            />
          </Route>

          {/*------------------------------ Auth Pass -------------------------------------------*/}
          <Route element={<AuthPass />}>
            <Route
              path="/meus-conteudos"
              element={
                <Container
                  card={AllContent}
                  path={"user"}
                  title={"Meus conteúdos"}
                />
              }
            />
            <Route
              path="/publicar-conteudo"
              element={
                <Container
                  card={PublicarConteúdo}
                  path={"user"}
                  title={"publicar conteúdo"}
                />
              }
            />
            <Route
              path="/publicar-fotos-e-videos"
              element={
                <Container
                  card={PublishMedia}
                  path={"user"}
                  title={"publicar fotos e videos"}
                />
              }
            />
            <Route
              path="/editar-conteudo/:query"
              element={
                <Container
                  card={EditContent}
                  path={"user"}
                  title={"Editar Conteúdo"}
                />
              }
            />
            <Route
              path="editar-perfil"
              element={
                <Container
                  card={Perfil}
                  path={"user"}
                  title={"editar perfil"}
                />
              }
            />
         

            <Route
              path="/minhas-compras"
              element={
                <Container
                  card={MyPurchases}
                  path={"user"}
                  title={"minhas compras"}
                />
              }
            />
            <Route
              path="/denunciar"
              element={
                <Container card={Reports} path={"user"} title={"denunciar"} />
              }
            />
            <Route
              path="/meu-histórico"
              element={
                <Container
                  card={MyHistory}
                  path={"user"}
                  title={"meu histórico"}
                />
              }
            />

            <Route
              path="/meus-pagamentos"
              element={
                <Container
                  card={MyPayments}
                  path={"user"}
                  title={"meus pagamentos"}
                />
              }
            />
            <Route
              path="/subscriptions"
              element={
                <Container
                  card={PlansSub}
                  path={"user"}
                  title={"planos e assinaturas"}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <Container
                  card={Settings}
                  path={"user"}
                  title={"configurações da conta"}
                />
              }
            />
            <Route
              path="/suporte"
              element={
                <Container card={Supports} path={"user"} title={"suporte"} />
              }
            />
          </Route>

          {/*------------------------------ admin panel -------------------------------------------*/}
          <Route element={<AdminAuth />}>
            <Route
              path="/admin-panel"
              element={
                <Container
                  card={AdminPanel}
                  path={"admin"}
                  title={"admin panel"}
                />
              }
            />
            <Route
              path="/create-blog-post"
              element={
                <Container
                  card={BlogCreate}
                  path={"admin"}
                  title={"create blog post"}
                />
              }
            />
          </Route>

          <Route
              path="/carrinho"
              element={
                <Container card={Carrinho} path={"user"} title={"carrinho"} />
              }
            />
        </Routes>
      </Suspense>
    </Router>
  );
}
