import React from "react";
import { CaretDown } from "../../data/Data";
import { Link } from "react-router-dom";
import ErrorCard from "./ErrorCard";

const To8s = ({
  svg: Svg,
  placehoder,
  value,
  dropdowns,
  dropdownId,
  toggleDropdown,
  options,
  handleSelect,
  name,
  error,
  bno,
}) => {
  const selectedOption = options?.find((option) => option.value === value);

  return (
    <div className={`${bno ? "" : "mb-3"}`}>
      <div
        className={`wm-dropdown-btn relative ${
          dropdowns[dropdownId] ? "wm-active" : ""
        }`}
        onClick={() => toggleDropdown(dropdownId)}
      >
        <div
          className={`flex justify-between ${
            bno ? "tc text-xs" : "btnbtn21 btc px-4"
          }  bg rounded-lg items-center py-2  `}
        >
          <div className="capitalize font-normal flex items-center">
            {Svg && (
              <span>
                <Svg size={14} color="var(--themeColor)" />
              </span>
            )}
            <span>{selectedOption ? selectedOption.name : placehoder}</span>
          </div>
          <CaretDown color={bno ? "var(--textColor)" : "var(--footText)"} />
        </div>
      </div>
      <div
        className={`wm-dropdown-containers absolute box-shadow rounded-lg  ${
          dropdowns[dropdownId] ? "wm-show" : ""
        }`}
      >
        <div className="btc py-2 overflow-y-auto max-h-72">
          {options?.map((option) => (
            <Link
              to={option.url || ""}
              key={option.value}
              className="flex mx-5"
              onClick={() => handleSelect(option.value, name)}
            >
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                className="wm-checkbox-circle"
                checked={value === option.value}
                onChange={() => handleSelect(option.value, name)}
              />
              <div
                onClick={() => handleSelect(option.value, name)}
                className="cursor-pointer"
              >
                {option.name}
              </div>
            </Link>
          ))}
        </div>
      </div>
      {error && <ErrorCard error={error} />}
    </div>
  );
};

export default To8s;
