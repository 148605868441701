import React from "react";
import ErrorCard from "./ErrorCard";

const To28 = ({ error, text, name, value, setValue, onChange, placeholder, sm }) => {
  return (
    <>
      <div className="flex items-center ">
        <div className="btnbtn rounded-md p-1 px-2 font-semibold text-sm theme">
          {text}
        </div>
        <input
          id={name}
          placeholder={placeholder}
          type="number"
          name={name}
          value={value}
          onChange={onChange ? onChange : (e) => setValue(e.target.value)}
          className={`input-btn-sm ${
            !sm ? "w-full" : "w-20"
          } p-1 -ml-2 btnbtn21 bg rounded-md`}
        />
        
      </div>
      {error && <ErrorCard error={error} />}
    </>
  );
};

export default To28;
