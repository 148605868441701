import React, { useEffect, useState } from "react";
import To15 from "../../cards/To15";
import To14 from "../../cards/To14";
import To8 from "../../cards/To8";
import To13 from "../../cards/To13";
import T11 from "../../cards/T11";
import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { db } from "../../../utils/init-firebase";
import { useGeneralBrain } from "../../../controller/Brain";
import { generateUid8 } from "../../../controller/Functions";
import To8s from "../../cards/To8s";
import { iddOptions } from "../../../data/Input";

const Step2 = ({
  name,
  cancelNow,
  setDropdowns,
  toggleDropdown,
  dropdowns,
}) => {
  const [id, setid] = useState("");
  const [cpf, setcpf] = useState("");
  const [img, setimg] = useState(null);
  const [vid, setvid] = useState(null);

  const [eid, seteid] = useState("");
  const [ecpf, setecpf] = useState("");
  const [eimg, seteimg] = useState("");
  const [evid, setevid] = useState("");

  const [isValidated, setValidated] = useState(false);
  const [loading, setloading] = useState(false);
  const { currentUser, userData } = useGeneralBrain();

  useEffect(() => {
    // Reset all error messages
    seteid("");
    setecpf("");
    seteimg("");
    setevid("");

    let isValid = true;

    // Regular expression to validate CPF format ###.###.###-##
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

    // Validate the ID field
    if (!id) {
      seteid("RG ou CNH é obrigatório");
      isValid = false;
    }

    // Validate the CPF field
    if (!cpf) {
      setecpf("CPF é obrigatório");
      isValid = false;
    } else if (!cpfRegex.test(cpf)) {
      setecpf("Formato de CPF inválido. Ex: 123.456.789-00");
      isValid = false;
    }

    // Validate the Image field
    if (!img) {
      seteimg("Imagem é obrigatória");
      isValid = false;
    }

    // Validate the Video field
    if (!vid) {
      setevid("Vídeo de comprovação é obrigatório");
      isValid = false;
    }

    // If all fields are valid, set the validation status to true
    setValidated(isValid);
  }, [id, cpf, img, vid]);

  const submitVerification = async (e) => {
    e.preventDefault();
    if (!id) {
      seteid("Id completo é obrigatório");
    } else if (!cpf) {
      setecpf("CPF usuário é obrigatório");
    } else if (!img) {
      seteimg("Image de usuário é obrigatório");
    } else if (!vid) {
      setevid("Video de usuário é obrigatório");
    } else {
      try {
        setloading(true);

        await runTransaction(db, async (transaction) => {
          const uniqueId = generateUid8();
          const todo = userData?.who === "escort" ? "criar" : "publicar";

          const userRef = doc(db, "users", currentUser?.uid);
          transaction.update(userRef, {
            isVerifiedStatus: "under review",
            isVerifiedVideo: vid,
            isVerifiedVideoDay: serverTimestamp(),
            todo,
          });

          const userVerificationRef = doc(
            db,
            "userVerifications",
            currentUser?.uid
          );

          transaction.set(userVerificationRef, {
            [uniqueId]: {
              id: id,
              cpf: cpf,
              img: img,
              vid: vid,
              time: serverTimestamp(),
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSelect1 = (selectedValue, name) => {
    setid(selectedValue); 
    setDropdowns(Array(18).fill(false));
  };

  return (
    <div className="mx-5 my-8">
      <To15
        title={"Comprovação de identidade"}
        subtitle={
          "Para a divulgação do seu anúncio na plataforma, precisamos confirmar sua identidade. Siga as instruções abaixo para o envio das informações solicitadas."
        }
      />
      <div>
        <To14 text={"Documentos"} />
        <div className="sm:grid sm:grid-cols-2 gap-5">
          <To8s
            placehoder={"RG ou CNH*"}
            value={id}
            dropdowns={dropdowns}
            dropdownId={2}
            toggleDropdown={toggleDropdown}
            options={iddOptions}
            handleSelect={handleSelect1}
            error={eid}
            name={'id'}
          />
          <To8
            placehoder={"CPF*"}
            value={cpf}
            onChange={(e) => setcpf(e.target.value)}
            error={ecpf}
            type={"text"}
          />
        </div>
        <To13
          t1={"Faça upload "}
          t2={"do seu documento de identificação"}
          t3={"PNG, JPG ou PDF até 10MB."}
          type={"file"}
          value={img}
          setValue={setimg}
          error={eimg}
          id={"img"}
        />
        <div className="theme-lighter my-3 rounded-md p-2 grid btc grid-cols-1">
          <T11
            text={
              "Anexe as fotos do seu RG, CNH ou outro documento que comprove as informações preenchidas;"
            }
            icon={"info"}
          />
          <T11
            text={`Verifique se o documento de identidade mostra seu nome completo como`}
            lu={name}
            icon={"info"}
          />
        </div>
      </div>
      <div className="mt-8">
        <To14 text={"Vídeo de comprovação"} />
        <To13
          t1={"Faça upload "}
          t2={"do seu vídeo de comprovação"}
          t3={"MP4 até 50MB"}
          type={"file"}
          value={vid}
          setValue={setvid}
          error={evid}
          id={"vid"}
        />
        <div className="theme-lighter my-3 rounded-md p-2 grid btc grid-cols-1">
          <T11 text={"O vídeo deve ter no mínimo 5 segundos;"} icon={"info"} />
          <T11
            text={`Filme seu corpo inteiro de frente e de costas;`}
            icon={"info"}
          />
          <T11
            text={`Diga em voz alta NOME DO SITE + data do dia que está sendo gravado.`}
            icon={"info"}
          />
          <T11
            text={`Não aceitaremos seu vídeo caso esteja fora do padrão explicado acima.`}
            icon={"info"}
            bold={true}
          />
        </div>
      </div>
      <div className="mt-10">
        <div
          onClick={!loading && isValidated ? submitVerification : undefined}
          className={`t1combtn themecom hover ${
            !isValidated && "opacity-65 cursor-not-allowed"
          }`} // Optionally disable styling
        >
          {loading ? "Uploading..." : "Continuar"}
        </div>
        <div onClick={cancelNow} className="t1combtn theme hover">
          Cancelar
        </div>
      </div>
    </div>
  );
};

export default Step2;
