export const activities = [
  {
    id: "SO",
    activity: "Faz sexo oral sem camisinha",
    cat: ["women", "men", "trans"],
  },
  {
    id: "RA",
    activity: "Recebe sexo anal com camisinha",
    cat: ["women", "men", "trans"],
  },
  { id: "RV", activity: "Recebe sexo vaginal com camisinha", cat: ["women"] },
  { id: "ST", activity: "Faz striptease", cat: ["women", "men", "trans"] },
  { id: "FM", activity: "Faz masturbação", cat: ["women", "men", "trans"] },
  {
    id: "TT",
    activity: "Faz massagem tântrica",
    cat: ["women", "men", "trans"],
  },
  {
    id: "OC",
    activity: "Faz sexo oral com camisinha",
    cat: ["women", "men", "trans"],
  },
  { id: "DO", activity: "Faz dominação", cat: ["women", "men", "trans"] },
  {
    id: "PB",
    activity: "Faz penetração com brinquedos sexuais",
    cat: ["women", "men", "trans"],
  },
  { id: "FP", activity: "Faz fetiche por pés", cat: ["women", "men", "trans"] },
  { id: "BO", activity: "Faz bondage", cat: ["women", "men", "trans"] },
  { id: "FI", activity: "Faz fisting", cat: ["women", "men", "trans"] },
  { id: "GD", activity: "Dá chuva dourada", cat: ["women", "men", "trans"] },
  { id: "VO", activity: "Voyeurismo", cat: ["women", "men", "trans"] },
  { id: "OU", activity: "Outro", cat: ["women", "men", "trans"] },
];

export const expediente = [
  { day: "Segunda-feira", start: "", end: "", work: true },
  { day: "Terça-feira", start: "", end: "", work: true },
  { day: "Quarta-feira", start: "", end: "", work: true },
  { day: "Quinta-feira", start: "", end: "", work: true },
  { day: "Sexta-feira", start: "", end: "", work: true },
  { day: "Sábado", start: "", end: "", work: true },
  { day: "Domingo", start: "", end: "", work: true },
];
export const locations = [
  { text: "Local próprio", name: "slown" },
  { text: "Hotéis", name: "slhotel" },
  { text: "Motéis", name: "slmotel" },
  { text: "Festas e eventos", name: "slevent" },
  { text: "A domicílio", name: "slhome" },
  { text: "Outro", name: "slother" },
];

export const amenities = [
  { text: "Ar-condicionado", name: "sgac" },
  { text: "Chuveiro", name: "sgshower" },
  { text: "Frigobar", name: "sgminibar" },
  { text: "Wi-fi", name: "sgwifi" },
  { text: "Televisão", name: "sgtv" },
  { text: "Estacionamento", name: "sgparking" },
];

export const paymentMethods = [
  { text: "Dinheiro", name: "pmmoney" },
  { text: "PIX", name: "pmpix" },
  { text: "Crédito", name: "pmcredit" },
  { text: "Débito", name: "pmdebit" },
];

export const regionsOptions = [
  { name: "Norte", value: "norte", info: "N", url: "/acompanhantes-norte" },
  {
    name: "Nordeste",
    value: "nordeste",
    info: "NE",
    url: "/acompanhantes-nordeste",
  },
  {
    name: "Centro-Oeste",
    value: "centro-oeste",
    info: "CO",
    url: "/acompanhantes-centro-oeste",
  },
  {
    name: "Sudeste",
    value: "sudeste",
    info: "SE",
    url: "/acompanhantes-sudeste",
  },
  { name: "Sul", value: "sul", info: "S", url: "/acompanhantes-sul" },
];

export const sexOptions = [
  { name: "male", value: "male", info: "M" },
  { name: "female", value: "female", info: "F" },
  { name: "Gay", value: "gay", info: "G" },
];

export const timeRegionsOptions = [
  { name: "UTC -12:00", value: "utc-12", info: "UTC-12" },
  { name: "UTC -11:00", value: "utc-11", info: "UTC-11" },
  { name: "UTC -10:00", value: "utc-10", info: "UTC-10" },
  { name: "UTC -09:00", value: "utc-9", info: "UTC-9" },
  { name: "UTC -08:00", value: "utc-8", info: "UTC-8" },
  { name: "UTC -07:00", value: "utc-7", info: "UTC-7" },
  { name: "UTC -06:00", value: "utc-6", info: "UTC-6" },
  { name: "UTC -05:00", value: "utc-5", info: "UTC-5" },
  { name: "UTC -04:00", value: "utc-4", info: "UTC-4" },
  { name: "UTC -03:00", value: "utc-3", info: "UTC-3" },
  { name: "UTC -02:00", value: "utc-2", info: "UTC-2" },
  { name: "UTC -01:00", value: "utc-1", info: "UTC-1" },
  { name: "UTC 00:00", value: "utc", info: "UTC" },
  { name: "UTC +01:00", value: "utc+1", info: "UTC+1" },
  { name: "UTC +02:00", value: "utc+2", info: "UTC+2" },
  { name: "UTC +03:00", value: "utc+3", info: "UTC+3" },
  { name: "UTC +04:00", value: "utc+4", info: "UTC+4" },
  { name: "UTC +05:00", value: "utc+5", info: "UTC+5" },
  { name: "UTC +06:00", value: "utc+6", info: "UTC+6" },
  { name: "UTC +07:00", value: "utc+7", info: "UTC+7" },
  { name: "UTC +08:00", value: "utc+8", info: "UTC+8" },
  { name: "UTC +09:00", value: "utc+9", info: "UTC+9" },
  { name: "UTC +10:00", value: "utc+10", info: "UTC+10" },
  { name: "UTC +11:00", value: "utc+11", info: "UTC+11" },
  { name: "UTC +12:00", value: "utc+12", info: "UTC+12" },
];

export const valorsOptions = [
  { name: "1 hora", value: "1 hora", info: "1" },
  { name: "30 minutos", value: "30 minutos", info: "F" },
  { name: "4 horas", value: "4 horas", info: "G" },
  { name: "2 horas", value: "2 horas", info: "G" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
  { name: "Diária", value: "Diária", info: "G" },
  { name: "Diário de viagem", value: "Diário de viagem", info: "G" },
];

export const serviceOptions = [
  { name: "acompanhantes", value: "escort", info: "E" },
];

export const timeOptions = [
  { name: "5 minutos", value: 5, info: "5" },
  { name: "8 minutos", value: 8, info: "8" },
  { name: "10 minutos", value: 10, info: "10" },
  { name: "12 minutos", value: 12, info: "12" },
  { name: "15 minutos", value: 15, info: "15" },
];

export const categoriaOptions = [
  { name: "Populares", value: "populares", info: "p" },
  { name: "Recentes", value: "recentes", info: "r" },
  { name: "Entrada", value: "entrada", info: "e" },
  { name: "Notícias", value: "notícias", info: "n" },
];
export const iddOptions = [
  { name: "RG", value: 'RG', info: "RG" },
  { name: "CNH", value: 'CNH', info: "CNH" },
];

export const booleanOptions = [
  { name: "Yes", value: true, info: "y" },
  { name: "No", value: false, info: "n" },
];

export const characters = [
  {
    placeholder: "Preferência sexual*",
    type: "options",
    name: "chapresex",
    option: [
      { name: "activo", value: "activo", info: "a" },
      { name: "passivo", value: "passivo", info: "p" },
    ],
  },
  {
    placeholder: "Clientes em conjunto*",
    type: "options",
    name: "chaclientes",
    option: [
      { name: "1 cliente", value: "1 cliente", info: "1" },
      { name: "2 clientes", value: "2 clientes", info: "2" },
      { name: "3 clientes", value: "3 clientes", info: "3" },
      { name: "4 ou mais clientes", value: "4 ou mais clientes", info: "4" },
    ],
  },
  {
    placeholder: "Altura (m)*",
    type: "number",
    name: "chaheight",
  },
  {
    placeholder: "Peso (kg)*",
    type: "number",
    name: "chaweight",
  },
  {
    placeholder: "Atende*",
    type: "options",
    name: "chaatende",
    option: [
      { name: "casais", value: "casais", info: "c" },
      { name: "homens", value: "homens", info: "h" },
      { name: "homens trans", value: "homens trans", info: "h" },
      { name: "mulheres", value: "mulheres", info: "m" },
      { name: "mulheres trans", value: "mulheres trans", info: "m" },
      { name: "nao binario", value: "nao binario", info: "n" },
    ],
  },
  {
    placeholder: "Cor dos olhos*",
    type: "options",
    name: "chaeyecolor",
    option: [
      { name: "azul", value: "azul", info: "a" },
      { name: "castanho", value: "castanho", info: "c" },
      { name: "cinza", value: "cinza", info: "c" },
      { name: "mel", value: "mel", info: "m" },
      { name: "verde", value: "verde", info: "v" },
      { name: "preto", value: "preto", info: "p" },
    ],
  },
  {
    placeholder: "Etnia*",
    type: "options",
    name: "chaethnicity",
    option: [
      { name: "branco", value: "branco", info: "b" },
      { name: "caboclo", value: "caboclo", info: "c" },
      { name: "cafuzo", value: "cafuzo", info: "c" },
      { name: "indegina", value: "indegina", info: "i" },
      { name: "mestico", value: "mestico", info: "m" },
      { name: "mulato", value: "mulato", info: "m" },
      { name: "negro", value: "negro", info: "n" },
      { name: "oriental", value: "oriental", info: "o" },
      { name: "pardo", value: "pardo", info: "p" },
    ],
  },
  {
    placeholder: "Estilo de cabelo*",
    type: "options",
    name: "chahairstyle",
    option: [
      { name: "loiro", value: "loiro", info: "l" },
      { name: "moreno", value: "moreno", info: "m" },
      { name: "ruivo", value: "ruivo", info: "r" },
      { name: "castanho", value: "castanho", info: "c" },
      { name: "colorido", value: "colorido", info: "c" },
      { name: "grisalho", value: "grisalho", info: "g" },
      { name: "sem cabelo", value: "sem cabelo", info: "s" },
    ],
  },
  {
    placeholder: "Tamanho de cabelo*",
    type: "options",
    name: "chahairsize",
    option: [
      { name: "pequeno", value: "pequeno", info: "p" },
      { name: "médio", value: "médio", info: "m" },
      { name: "cheio", value: "cheio", info: "c" },
    ],
  },
  {
    placeholder: "Idiomas*",
    type: "options",
    name: "chalanguage",
    option: [
      { name: "portugues", value: "portugues", info: "p" },
      { name: "alemao", value: "alemao", info: "a" },
      { name: "espanhol", value: "espanhol", info: "e" },
      { name: "frances", value: "frances", info: "f" },
      { name: "ingles", value: "ingles", info: "i" },
      { name: "italiano", value: "italiano", info: "i" },
      { name: "outro", value: "outro", info: "o" },
    ],
  },
  {
    placeholder: "Silicone*",
    type: "options",
    name: "chasilicone",
    option:booleanOptions,
  },
  {
    placeholder: "Tamanho do pé*",
    type: "number",
    name: "chafootsize",
  },
  {
    placeholder: "Piercings*",
    type: "options",
    name: "chapiercing",
    option:booleanOptions,
  },
  {
    placeholder: "Tatuagens*",
    type: "options",
    name: "chatatoo",
    option:booleanOptions,
  },
  {
    placeholder: "Fumante*",
    type: "options",
    name: "chafumante",
    option:booleanOptions,
  },
  
];
