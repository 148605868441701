import React, { useState } from "react";
import {
  Love1Icon,
  LoveIcon,
  PeepIcon,
  PFPIcon,
  Share1Icon,
} from "../../data/Data";
import { TimeAgo, ToProfile } from "../../controller/Functions";
import { Link, useNavigate } from "react-router-dom";
import { IconText } from "../../controller/components";
import { db } from "../../utils/init-firebase";
import {
  arrayRemove,
  arrayUnion,
  doc,
  increment,
  runTransaction,
} from "firebase/firestore";
import { useGeneralBrain } from "../../controller/Brain";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "../../data/Data";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import ImageCard from "./ImageCard";

const PostCard = ({ data, list, docId, setMyList, setMyData, myData }) => {
  const [show, setshow] = useState(false);
  const { userData } = useGeneralBrain();
  const navigate = useNavigate();

  const isLiked = list.includes(data.id);
  const shareUrl = `${window.location.href}/post/${data.id}`;

  const handleLike = async () => {
    if (!userData) {
      navigate("/login");
      return;
    }

    await runTransaction(db, async (transaction) => {
      setMyData((prevData) =>
        prevData.map((post) => {
          if (post.id === data.id) {
            return { ...post, l: post.l + (isLiked ? -1 : 1) };
          }
          return post;
        })
      );

      const listRef = doc(db, "lists", userData?.userId);
      if (isLiked) {
        // Remove the id if it's already liked
        transaction.update(listRef, {
          posts: arrayRemove(data.id),
        });
        setMyList((prevList) => prevList.filter((id) => id !== data.id));
      } else {
        setMyList((prevList) => [...prevList, data.id]);
        // Add the id if it's not liked
        transaction.update(listRef, {
          posts: arrayUnion(data.id),
        });
      }

      const liveRef = doc(db, "lives", docId);
      const count = "l";

      transaction.update(liveRef, {
        [`${data.id}.${count}`]: increment(isLiked ? -1 : 1),
      });
    });
  };

  const Comp = ({ btn: Btn, text, svg: Svg }) => {
    return (
      <div className="flex justify-center items-center py-2 hover-bb">
        <Btn url={shareUrl}>
          <div className="flex justify-center items-center">
            <Svg color={"#505862"} />
            <div>{text}</div>
          </div>
        </Btn>
      </div>
    );
  };

  return (
    <div className="btnbtn21 p-5 rounded-2xl mb-5">
      <Link to={ToProfile(data.u)}>
        <div className="flex items-center mb-5 sm:mb-5">
          <PFPIcon />
          <div className="font-bold texst-sm ml-1 capitalize">{data?.n}</div>
        </div>
      </Link>
      <div className="w-full h-96 bglb height-lg">
        {data.type === "image" && (
          <ImageCard
            src={data.url}
            alt=""
            className="object-cover bglb w-full h-full"
          />
        )}
        {data.type === "video" && (
          <video
            src={data.url}
            alt=""
            className="object-cover bglb w-full h-full"
            controls
            autoPlay // Automatically plays the video
            muted
            loop
          />
        )}
      </div>
      <div className="flex justify-between items-center my-5">
        <div
          onClick={() => handleLike(data.id)}
          className="flex justify-between items-center"
        >
          {data.l}{" "}
          <IconText
            svg={isLiked ? LoveIcon : Love1Icon}
            tsvg={isLiked}
            text={"Curtir"}
          />
        </div>
        <div onClick={() => setshow(show ? false : true)} className="relative">
          <IconText svg={Share1Icon} text={"Compartilhar"} />
          {show && (
            <div className="overflow-hidden box-shadow bg text-xs w-40 sm:w-52 rounded-lg bottom-5 absolute border-top-brown border-bottom-brown my-1">
              <Comp
                btn={FacebookShareButton}
                svg={FacebookIcon}
                text={"Facebook"}
              />
              <div className="border-bottom-brown border-top-brown">
                <Comp
                  btn={WhatsappShareButton}
                  svg={WhatsappIcon}
                  text={"Whatsapp"}
                />
              </div>
              <Comp
                btn={TwitterShareButton}
                svg={TwitterIcon}
                text={"Twitter"}
              />
            </div>
          )}
        </div>
        <Link to={ToProfile(data.u)}>
          <IconText svg={PeepIcon} text={"Ver perfil"} />
        </Link>
      </div>
      <div className="text-sm font-normal">Postado {TimeAgo(data.t)}</div>
    </div>
  );
};

export default PostCard;
