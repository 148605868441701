// info.js

import { AdressIcon, EmailIcon, IdCardIcon, RecieptIcon } from "./Data";

export const projectName = "peppermodel";

export const domain = window.location.origin;

export const level = "level 1";
export const levelNum = 1;
export const name = "focus";
export const email = "focus@gmail.com";

export const verificationStatus = "";

export const levels = {
  1: {
    svg: EmailIcon,
    verified: true,
    a1: 10000,
    a2: 20000,
    a3: 50000,
    a4: 100000,
    tr: "Requirement",
    v1: "Verify Email",
  },
  2: {
    svg: IdCardIcon,
    verified: false,
    a1: 100000,
    a2: 200000,
    a3: 500000,
    a4: 1000000,
    tr: "Requirements",
    v1: "ID Card or NIN Photo",
    v2: "A Clear Selfie Photo",
  },
  3: {
    svg: AdressIcon,
    verified: false,
    a1: 1000000,
    a2: 2000000,
    a3: 5000000,
    a4: 10000000,
    tr: "Requirements",
    v1: "Proof of Address or Utility Bill",
    v2: "Plastic ID Card or Passport",
  },
  4: {
    svg: RecieptIcon,
    verified: false,
    a1: 10000000,
    a2: 20000000,
    a3: 50000000,
    a4: 100000000,
    tr: "Requirements",
    v1: "Proof to Source of Wealth",
    v2: "Recent Bank Account Statement",
  },
};

export const planInfo = (plan) => {
  let btnbtn;
  let planText;
  let planTextbc;
  let planTexttc;
  let planTextbr;
  let bgClass;
  let nameClass;
  let textClass;
  let lineClass;
  let iconClass;
  let iconsClass;

  let planTitle;
  let planBorder;
  let planBoxbg;
  let planBoxtc;
  let planhoBorder;

  if (plan === "free") {
    btnbtn = "btnbtn21";
    planText = "";
    nameClass = "tc";
    textClass = "tc";
    lineClass = "border-bottom-brown";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";

    planTitle = "";
  } else if (plan === "basic") {
    btnbtn = "btnbtn";
    planText = "basic";
    planTextbc = "bgt";
    planTexttc = "tcw";
    planTextbr = "btnbtn3";
    nameClass = "theme";
    textClass = "tc";
    lineClass = "border-bottom-theme";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";

    planTitle = "Essencial Basic";
    planBorder = "btnbtn21";
    planBoxbg = "bglb";
    planBoxtc = "";
    planhoBorder = "border-top-brown border-bottom-brown";
  } else if (plan === "pro") {
    btnbtn = "btnbtn";
    planText = "pro";
    planTextbc = "themecom";
    planTexttc = "tcw";
    planTextbr = "btnbtn";
    nameClass = "tcw";
    textClass = "tcw";
    lineClass = "border-bottom-white";
    iconClass = "var(--white)";
    bgClass = "themecom";
    iconsClass = "var(--white)";

    planTitle = "Ascensão Pro";
    planBorder = "btnbtn321";
    planBoxbg = "bgltl";
    planBoxtc = "theme";
    planhoBorder = "border-top-theme border-bottom-theme";
  } else if (plan === "black") {
    btnbtn = "btnbtn";
    planText = "black";
    planTextbc = "bgb";
    planTexttc = "tcw";
    planTextbr = "btnbtn4";
    nameClass = "tcw";
    textClass = "tcw";
    lineClass = "border-bottom-white";
    iconClass = "var(--themeColor)";
    bgClass = "bgb";
    iconsClass = "var(--white)";

    planTitle = "Estrela Black";
    planBorder = "btnbtn4";
    planBoxbg = "bglb";
    planBoxtc = "";
    planhoBorder = "border-top-deep border-bottom-deep";
  } else if (plan === "tele") {
    btnbtn = "btnbtn";
    planText = "";
    nameClass = "theme";
    textClass = "tc";
    lineClass = "border-bottom-theme";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";

    planTitle = "Ver telefone";
    planBorder = "btnbtn";
    planBoxbg = "";
    planBoxtc = "";
    planhoBorder = "border-top-deep border-bottom-deep";
  }
  const planDetail = {
    btnbtn,
    planText,
    planTextbc,
    planTexttc,
    planTextbr,
    bgClass,
    nameClass,
    textClass,
    lineClass,
    iconClass,
    iconsClass,
    planTitle,
    planBorder,
    planBoxbg,
    planBoxtc,
    planhoBorder,
  };
  return planDetail;
};
